import { IonContent, IonPage } from '@ionic/react';
import './Home.scss';

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen className='main'>
        <SectionIntro />
        <SectionFromEverywhere />
        <SectionFeatures />
        <SectionECommerce />
        <SectionInstall />
      </IonContent>
    </IonPage>
  );
};

const SectionIntro = () => {
  return (
    <div className="full-screen  section-1">
      <img src='https://dglstorage.com/files/EzPOSApp/web/rectangle.png' alt='rect' className='full-screen  abs-full' />
      <img src='https://dglstorage.com/files/EzPOSApp/web/wave1.png' alt='wave1' className='full-screen  abs-full' />
      <div className='full-screen  abs-full center flex-column-container'>
        <div className='flex'>
          <h1 className='title color-white'>Ez POS</h1>
          <h3 className='subtitle color-white'>Online Cashier App</h3>
        </div>
        <div className='overflow-hidden flex-3'>
          <img src='https://dglstorage.com/files/EzPOSApp/web/ss.png' alt='ss' className='ss' />
        </div>
      </div>
    </div>
  )
}

const SectionFromEverywhere = () => {
  return (
    <div className="full-screen section-2">
      <div className='full-screen abs-full center flex-column-container'>
        <div className='flex'>
          <h5 className='subtitle color-light-purple'>Monitor Your Store</h5>
          <h2 className='title color-dark-purple'>From Everywhere</h2>
        </div>
        <div className='overflow-hidden flex-3'>
          <img src='https://dglstorage.com/files/EzPOSApp/web/fromeverywhere.png' alt='ss' className='m-center' />
        </div>
      </div>
    </div>
  )
}

const SectionFeatures = () => {
  return (
    <div className="full-screen section-3">
      <div className='full-screen abs-full center flex-row-container m-flex-vertical'>
        <div className='overflow-hidden flex left-container'>
          <img src='https://dglstorage.com/files/EzPOSApp/web/features.png' alt='ss' className='ss' />
        </div>
        <div className='flex left right-container'>
          <h2 className='title color-dark-purple'>Features</h2>
          <hr className='hr-1' />
          <div className='text color-light-purple'>
            <p>Sales</p>
            <p>Purchasing</p>
            <p>Stock</p>
            <p>Buyer</p>
            <p>Supplier</p>
            <p>Staff Access</p>
            <p>Delayed Transactions</p>
            <p>POS Printing</p>
            <p>Digital Billing</p>
            <p>Free Online Shop</p>
            <p>Detailed Reports</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const SectionECommerce = () => {
  return (
    <div className="full-screen section-4">
      <img src='https://dglstorage.com/files/EzPOSApp/web/wave2.png' alt='rect' className='full-screen abs-full' style={{ border: '0' }} />
      <div className='full-screen abs-full center flex-row-container m-flex-vertical'>
        <div className='overflow-hidden flex left-container'>
          <img src='https://dglstorage.com/files/EzPOSApp/web/ecommerce.png' alt='ss' className='ss ss-ecommerce' />
        </div>
        <div className='flex left right-container'>
          <h2 className='title color-white'>Get Free</h2>
          <h3 className='subtitle color-white'>Online Shop</h3>
          <hr className='hr-2' />
          <div className='text color-white'>
            <p>
              The stock data is
              synchronized with your
              cashier.
            </p>
            <p>
              Free SSL encrypted
              ECommerce online shop.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const SectionInstall = () => {
  return (
    <div className="section-5">
      <div className='half-screen center'>
        <div className='title-container'>
          <h3 className='title color-white'>Install Now</h3>
          <br />
        </div>
        <div className='overflow-hidden flex-row-container m-flex-vertical'>
          <div className='flex right'>
            <a href="https://play.google.com/store/apps/details?id=com.digilifesoftware.ezposapp">
              <img src='https://dglstorage.com/files/EzPOSApp/web/googlestore.png' alt='ss' className='ss logo logo-left' />
            </a>
          </div>
          <div className='flex left'>
            <img src='https://dglstorage.com/files/EzPOSApp/web/appstore.png' alt='ss' className='ss logo logo-right' style={{ height: '70px' }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home;
